const clientUrl = 'https://test.thrivable.app';
export const environment = {
  production: false,
  serverUrl: 'https://api.test.thrivable.app',
  clientUrl: clientUrl,
  saml_provider: 'Google-SAML',
  recaptchaKey: '6LcloyIoAAAAAMCpgEOT00M7US30APWz8SfzFKJJ',
  awsConfig: {
    aws_project_region: 'us-east-2',
    aws_cognito_region: 'us-east-2',
    aws_user_pools_id: 'us-east-2_kQaxhBq7p',
    aws_user_pools_web_client_id: '6118iake06suq4agtakbmu2011',
    oauth: {
      scope: [],
      domain: 'thrivable-test.auth.us-east-2.amazoncognito.com',
      redirectSignIn: `${clientUrl}/account/create`,
      redirectSignOut: `${clientUrl}/account/signin`,
      responseType: 'code',
    },
  },
  sentry: {
    enabled: true,
    dsn: 'https://e982231a3c34c3ff8d2c12a3512434d7@o4504181415936000.ingest.us.sentry.io/4507102211932160',
    tracesSampleRate: 1.0,
    environmentName: 'test',
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 0.0,
  },
};
